import React, { useState } from "react";
import KiwiLogo from "../assets/images/Kiwi-Login.png";
import LoginPageImage from "../assets/images/Login-Page-Image.png";
import { FaArrowRightLong, FaEye, FaEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { SIGNUP_API } from "../utils/constant";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [phone, setPhone] = useState({
    code: "+91",
    number: "",
  });
  const [auth, setAuth] = useState({
    email: "",
    country: "",
    gender: "",
    password: "",
  });

  const validateInput = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!auth.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(auth.email)) {
      newErrors.email = "Invalid email format.";
    }

    if (!phone.number || phone.number.length < 10) {
      newErrors.phone = "Valid phone number is required.";
    }

    if (!auth.password) {
      newErrors.password = "Password is required.";
    } else if (auth.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }

    if (!auth.country) {
      newErrors.country = "Country is required.";
    }

    if (!auth.gender) {
      newErrors.gender = "Gender is required.";
    }

    return newErrors;
  };

  const navigate = useNavigate();
  const user = false;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateInput();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(SIGNUP_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: auth.email,
          password: auth.password,
          phone: phone.code + phone.number,
          country: auth.country,
          gender: auth.gender,
        }),
      });

      if (!response.ok) {
        console.error(`Error: ${response.status} ${response.statusText}`);
        setLoading(false);
        return;
      }

      const resJson = await response.json();
      if (!user) {
        navigate("/dashboard", { state: { flag: true } });
      }

      console.log("SignUp Successful:", resJson);
    } catch (error) {
      console.error("Error occurred during signup:", error.message);
    }
    setLoading(false);
  };

  const countries = ["India", "United States", "Canada", "Australia"];
  const genders = ["Male", "Female", "Other"];

  return (
    <div className=" max-h-screen overflow-hidden flex items-center justify-center px-2 sm:px-4">
      <div className="bg-white h-screen w-full  rounded-lg  overflow-hidden grid grid-cols-1 lg:grid-cols-2 ">
        {/* Left Section */}
        <div className="px-6 sm:px-10  flex flex-col justify-center relative bottom-[25px]">
          <div className="flex items-center justify-between mb-6">
            <img className="h-[120px] w-[120px]" src={KiwiLogo} alt="Logo" />
          </div>
          <div className="relative bottom-[40px]">
            <p className="text-gray-600 text-lg ">Welcome Back !!!</p>
            <h1 className="font-bold text-3xl text-[#2F5B20] mb-6">SignUp</h1>
          </div>
          <form onSubmit={handleSubmit} className="space-y-2 w-full max-w-md">
            {/* Email */}
            <div className="relative">
              <label className="block text-sm font-semibold mb-2">Email</label>
              <input
                className="bg-[#C0DBEA] w-full h-[46px] px-3 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                type="email"
                placeholder="admin@gmail.com"
                onChange={(e) =>
                  setAuth((prevAuth) => ({
                    ...prevAuth,
                    email: e.target.value,
                  }))
                }
              />
              <div className="h-5">
                {error.email && (
                  <p className="absolute text-red-500 text-xs mt-1">
                    {error.email}
                  </p>
                )}
              </div>
            </div>

            {/* Phone Number */}
            <div className="relative">
              <label className="block text-sm font-semibold mb-2">
                Phone Number
              </label>
              <div className="flex">
                <select
                  onChange={(e) =>
                    setPhone((prevPhone) => ({
                      ...prevPhone,
                      code: e.target.value,
                    }))
                  }
                  className="bg-[#C0DBEA] text-gray-700 w-[100px] h-[46px] rounded-l-md px-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                  defaultValue="+91"
                >
                  <option value="+91">+91 (India)</option>
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  <option value="+61">+61 (Australia)</option>
                </select>
                <input
                  onChange={(e) =>
                    setPhone((prevPhone) => ({
                      ...prevPhone,
                      number: e.target.value,
                    }))
                  }
                  className="bg-[#C0DBEA] flex-1 h-[46px] px-3 rounded-r-md border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                  type="text"
                  placeholder="1234567890"
                />
              </div>
              <div className="h-5">
                {error.phone && (
                  <p className="absolute text-red-500 text-xs mt-1">
                    {error.phone}
                  </p>
                )}
              </div>
            </div>

            {/* Country and Gender */}
            <div className="flex space-x-4">
              <div className="w-1/2 relative">
                <label className="block text-sm font-semibold mb-2">
                  Country
                </label>
                <select
                  onChange={(e) =>
                    setAuth((prevAuth) => ({
                      ...prevAuth,
                      country: e.target.value,
                    }))
                  }
                  className="bg-[#C0DBEA] w-full h-[46px] rounded-md px-3 border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select country
                  </option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                <div className="h-5">
                  {error.country && (
                    <p className="absolute text-red-500 text-xs mt-1">
                      {error.country}
                    </p>
                  )}
                </div>
              </div>

              <div className="w-1/2 relative">
                <label className="block text-sm font-semibold mb-2">
                  Gender
                </label>
                <select
                  onChange={(e) =>
                    setAuth((prevAuth) => ({
                      ...prevAuth,
                      gender: e.target.value,
                    }))
                  }
                  className="bg-[#C0DBEA] w-full h-[46px] rounded-md px-3 border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select gender
                  </option>
                  {genders.map((gender, index) => (
                    <option key={index} value={gender}>
                      {gender}
                    </option>
                  ))}
                </select>
                <div className="h-5">
                  {error.gender && (
                    <p className="absolute text-red-500 text-xs mt-1">
                      {error.gender}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Password */}
            <div className="relative">
              <label className="block mb-2 text-sm font-medium">Password</label>
              <input
                onChange={(e) =>
                  setAuth((prevAuth) => ({
                    ...prevAuth,
                    password: e.target.value,
                  }))
                }
                className="bg-[#C0DBEA] w-full h-[46px] px-3 pr-10 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-[#3E782A]"
                type={showPassword ? "text" : "password"}
                placeholder="..........."
              />
              <button
                type="button"
                className="absolute inset-y-0 right-3 top-7 text-green-800 flex items-center  hover:text-green-600"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              {error.password && (
                <p className="text-red-500 text-sm mt-1">{error.password}</p>
              )}
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`bg-[#3E782A] w-full h-[46px] rounded-full text-white flex items-center justify-center gap-3 hover:bg-opacity-90 transition-colors duration-300 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {loading ? (
                <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-white"></div>
              ) : (
                <>
                  SignUp <FaArrowRightLong />
                </>
              )}
            </button>
          </form>
        </div>

        {/* Right Section */}
        <div className="hidden lg:flex bg-[#3E782A] items-center justify-center relative ">
          <img
            src={LoginPageImage}
            alt="Login Illustration"
            className="h-[70%] object-contain max-w-full relative right-[330px]"
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
