import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Logo from "../assets/images/Kiwi-Login.png"

const AlternativeSplashScreen = () => {
  const [loadingStage, setLoadingStage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const stages = [
      { duration: 1000, stage: 1 },
      { duration: 1000, stage: 2 },
      { duration: 1000, stage: 3 }
    ];

    let stageIndex = 0;

    const stageTimer = setInterval(() => {
      if (stageIndex < stages.length) {
        setLoadingStage(stages[stageIndex].stage);
        stageIndex++;
      } else {
        clearInterval(stageTimer);
        
        // Navigation logic
        // const isLoggedIn = localStorage.getItem("login") === "true";
        // if (isLoggedIn) {
        //   navigate("/login");
        // } else {
        //   navigate("/dashboard");
        // }
        navigate("/login")
      }
    }, 1000);

    return () => clearInterval(stageTimer);
  }, []);

  const getStageContent = () => {
    switch(loadingStage) {
      case 1:
        return {
          title: "Warming Up",
          description: "Preparing your personalized experience",
          color: "bg-green-500"
        };
      case 2:
        return {
          title: "Getting Ready",
          description: "Gathering your workspace",
          color: "bg-blue-500"
        };
      case 3:
        return {
          title: "Almost There",
          description: "Final touches in progress",
          color: "bg-purple-500"
        };
      default:
        return {
          title: "Welcome",
          description: "Initializing Kiwi",
          color: "bg-indigo-500"
        };
    }
  };

  const stageContent = getStageContent();

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center h-screen w-screen bg-gradient-to-br from-indigo-100 to-purple-200 overflow-hidden">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-96 h-96 bg-white rounded-2xl shadow-2xl flex flex-col justify-center items-center p-8 text-center relative overflow-hidden"
      >
        {/* Animated Background Indicator */}
        <motion.div
          className={`absolute top-0 left-0 w-full h-2 ${stageContent.color}`}
          initial={{ width: 0 }}
          animate={{ width: '100%' }}
          transition={{ duration: 1 }}
        />

        {/* Animated Logo/Icon */}
        <motion.div
          className="mb-6"
          initial={{ rotate: -180, scale: 0.5 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          {/* <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="80" 
            height="80" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="text-indigo-600"
          >
            <path d="M12 2L2 7l10 5 10-5-10-5z" />
            <path d="m2 17 10 5 10-5" />
            <path d="m2 12 10 5 10-5" />
          </svg> */}
          <img className="h-36" src={Logo}/>
          <h1 className="font-bold">Welcome to Kiwi</h1>
        </motion.div>

        {/* Stage Title */}
        <motion.h2
          key={stageContent.title}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-2xl font-bold text-gray-800 mb-4"
        >
          {stageContent.title}
        </motion.h2>

        {/* Stage Description */}
        <motion.p
          key={stageContent.description}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-600 mb-6"
        >
          {stageContent.description}
        </motion.p>

        {/* Loading Dots */}
        <div className="flex space-x-2">
          {[0, 1, 2].map((dot) => (
            <motion.div
              key={dot}
              className={`w-3 h-3 rounded-full ${
                dot < loadingStage ? stageContent.color : 'bg-gray-300'
              }`}
              initial={{ scale: 0.5 }}
              animate={{ scale: dot < loadingStage ? 1 : 0.5 }}
              transition={{ type: "spring" }}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default AlternativeSplashScreen;