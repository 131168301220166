// import React, { useState } from 'react';
// import {
//   HelpCircle,
//   Mail,
//   Phone,
//   MessageCircle,
//   ChevronDown,
//   Send,
//   X,
//   User,
//   Clock,
//   Check,
//   AlertCircle,
//   MoreVertical,
// } from 'lucide-react';

// // Mock data for active chats
// const initialChats = [
//   {
//     id: 1,
//     user: 'John Doe',
//     email: 'john@example.com',
//     status: 'active',
//     unread: 2,
//     lastMessage: 'I need help with my order',
//     timestamp: '2 mins ago',
//     messages: [
//       { id: 1, text: 'Hello, I need help with my order', isUser: true, timestamp: '10:30 AM' },
//       { id: 2, text: 'Can anyone assist me?', isUser: true, timestamp: '10:31 AM' },
//     ],
//   },
//   {
//     id: 2,
//     user: 'Jane Smith',
//     email: 'jane@example.com',
//     status: 'waiting',
//     unread: 1,
//     lastMessage: 'Payment issue with my subscription',
//     timestamp: '5 mins ago',
//     messages: [
//       { id: 1, text: 'Hi, I\'m having trouble with my payment', isUser: true, timestamp: '10:25 AM' },
//     ],
//   },
//   {
//     id: 3,
//     user: 'Jane andrew',
//     email: 'jane@example.com',
//     status: 'waiting',
//     unread: 1,
//     lastMessage: 'Payment issue with my subscription',
//     timestamp: '5 mins ago',
//     messages: [
//       { id: 1, text: 'Hi, I\'m having trouble with my payment', isUser: true, timestamp: '10:25 AM' },
//     ],
//   },
// ];

// const AdminChatWindow = () => {
//   const [chats, setChats] = useState(initialChats);
//   const [selectedChat, setSelectedChat] = useState(null);
//   const [newMessage, setNewMessage] = useState('');
//   const [filter, setFilter] = useState('all'); // all, active, waiting, closed

//   const handleSendMessage = (e) => {
//     e.preventDefault();
//     if (!newMessage.trim() || !selectedChat) return;

//     const newAdminMessage = {
//       id: Date.now(),
//       text: newMessage,
//       isUser: false,
//       timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
//     };

//     // Update the chat list with the new message
//     setChats((prevChats) =>
//       prevChats.map((chat) =>
//         chat.id === selectedChat.id
//           ? { ...chat, messages: [...chat.messages, newAdminMessage] }
//           : chat
//       )
//     );

//     // Update the selected chat directly
//     setSelectedChat((prevChat) => ({
//       ...prevChat,
//       messages: [...prevChat.messages, newAdminMessage],
//     }));

//     setNewMessage('');
//   };

//   const markChatAsRead = (chatId) => {
//     setChats((prevChats) =>
//       prevChats.map((chat) =>
//         chat.id === chatId ? { ...chat, unread: 0 } : chat
//       )
//     );
//   };

//   const handleSelectChat = (chat) => {
//     setSelectedChat(chat);
//     markChatAsRead(chat.id);
//   };

//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'active':
//         return 'bg-green-500';
//       case 'waiting':
//         return 'bg-yellow-500';
//       case 'closed':
//         return 'bg-gray-500';
//       default:
//         return 'bg-gray-500';
//     }
//   };

//   return (
//     <div className="h-screen flex">
//       {/* Chat List Sidebar */}
//       <div className="w-80 border-r dark:border-gray-700 bg-gray-200 dark:bg-gray-800">
//         <div className="p-4 border-b dark:border-gray-700">
//           <h2 className="text-xl font-bold text-gray-800 dark:text-white">Support Dashboard</h2>
//           <div className="mt-4 flex space-x-2">
//             <button
//               onClick={() => setFilter('all')}
//               className={`px-3 py-1 rounded-full text-sm ${
//                 filter === 'all'
//                   ? 'bg-blue-500 text-white'
//                   : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300'
//               }`}
//             >
//               All
//             </button>
//             <button
//               onClick={() => setFilter('active')}
//               className={`px-3 py-1 rounded-full text-sm ${
//                 filter === 'active'
//                   ? 'bg-blue-500 text-white'
//                   : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300'
//               }`}
//             >
//               Active
//             </button>
//             <button
//               onClick={() => setFilter('waiting')}
//               className={`px-3 py-1 rounded-full text-sm ${
//                 filter === 'waiting'
//                   ? 'bg-blue-500 text-white'
//                   : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300'
//               }`}
//             >
//               Waiting
//             </button>
//           </div>
//         </div>

//         <div className="overflow-y-auto h-[calc(100vh-88px)]">
//           {chats
//             .filter((chat) => filter === 'all' || chat.status === filter)
//             .map((chat) => (
//               <div
//                 key={chat.id}
//                 onClick={() => handleSelectChat(chat)}
//                 className={`p-4 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 ${
//                   selectedChat?.id === chat.id ? 'bg-blue-50 dark:bg-gray-700' : ''
//                 }`}
//               >
//                 <div className="flex justify-between items-start mb-1">
//                   <div className="flex items-center">
//                     <User className="w-4 h-4 mr-2 text-gray-500" />
//                     <h3 className="font-medium text-gray-800 dark:text-white">{chat.user}</h3>
//                   </div>
//                   <span className="text-xs text-gray-500">{chat.timestamp}</span>
//                 </div>
//                 <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">{chat.lastMessage}</p>
//                 <div className="flex justify-between items-center">
//                   <span
//                     className={`inline-flex items-center px-2 py-1 rounded-full text-xs ${getStatusColor(
//                       chat.status
//                     )} text-white`}
//                   >
//                     {chat.status}
//                   </span>
//                   {chat.unread > 0 && (
//                     <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-full">
//                       {chat.unread}
//                     </span>
//                   )}
//                 </div>
//               </div>
//             ))}
//         </div>
//       </div>

//       {/* Chat Window */}
//       <div className="flex-1 flex flex-col bg-gray-50 dark:bg-gray-900">
//         {selectedChat ? (
//           <>
//             {/* Chat Header */}
//             <div className="p-4 bg-gray-200 dark:bg-gray-800 border-b dark:border-gray-700 flex justify-between items-center">
//               <div>
//                 <h3 className="font-medium text-gray-800 dark:text-white">{selectedChat.user}</h3>
//                 <p className="text-sm text-gray-500">{selectedChat.email}</p>
//               </div>
//               <div className="flex items-center space-x-4">
//                 <button className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
//                   <AlertCircle className="w-5 h-5" />
//                 </button>
//                 <button className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
//                   <MoreVertical className="w-5 h-5" />
//                 </button>
//               </div>
//             </div>

//             {/* Messages */}
//             <div className="flex-1 overflow-y-auto p-4 space-y-4">
//               {selectedChat.messages.map((message) => (
//                 <div
//                   key={message.id}
//                   className={`flex ${message.isUser ? 'justify-start' : 'justify-end'}`}
//                 >
//                   <div
//                     className={`max-w-[70%] p-3 rounded-lg ${
//                       message.isUser
//                         ? 'bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200'
//                         : 'bg-blue-500 text-white'
//                     }`}
//                   >
//                     <p>{message.text}</p>
//                     <span className="text-xs opacity-75 mt-1 block">{message.timestamp}</span>
//                   </div>
//                 </div>
//               ))}
//             </div>

//             {/* Message Input */}
//             <form
//               onSubmit={handleSendMessage}
//               className="p-4 bg-white dark:bg-gray-800 border-t dark:border-gray-700"
//             >
//               <div className="flex space-x-2">
//                 <input
//                   type="text"
//                   value={newMessage}
//                   onChange={(e) => setNewMessage(e.target.value)}
//                   placeholder="Type your response..."
//                   className="flex-1 p-2 border dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
//                 />
//                 <button
//                   type="submit"
//                   className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
//                 >
//                   <Send className="w-5 h-5" />
//                 </button>
//               </div>
//             </form>
//           </>
//         ) : (
//           <div className="flex-1 flex items-center justify-center">
//             <p className="text-gray-500 dark:text-gray-400">Select a chat to start responding</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default AdminChatWindow;
import React, { useState, useEffect, useRef } from "react";
import { formatDistanceToNow } from "date-fns";
import { Send, Search, Loader, User, Check, CheckCheck } from "lucide-react";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";

const BACKEND_URL = "https://kiwii.shop:5000";

const AdminMessageDashboard = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [unreadCounts, setUnreadCounts] = useState({});
  const socketRef = useRef(null);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  // Initialize socket connection
  useEffect(() => {
    socketRef.current = io(BACKEND_URL, {
      transports: ['websocket'],
      withCredentials: true
    });

    socketRef.current.on("new_message", (data) => {
      handleNewMessage(data);
      // Update unread count for conversation
      setUnreadCounts(prev => ({
        ...prev,
        [data.conversationId]: (prev[data.conversationId] || 0) + 1
      }));
    });

    socketRef.current.on("conversation_update", handleConversationUpdate);

    if (selectedConversation) {
      socketRef.current.emit("join_conversation", selectedConversation?._id);
    }

    return () => {
      if (socketRef.current) {
        if (selectedConversation) {
          socketRef.current.emit("leave_conversation", selectedConversation?._id);
        }
        socketRef.current.disconnect();
      }
    };
  }, [selectedConversation]);

  // Mark messages as read
  const markMessagesAsRead = async (messageId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/v1/messages/${messageId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        setMessages(prevMessages =>
          prevMessages.map(msg =>
            msg?._id === messageId ? { ...msg, read: true } : msg
          )
        );
      }
    } catch (error) {
      console.error("Error marking message as read:", error);
    }
  };

  // Mark messages as read when they become visible
  const handleMessageVisible = async (message) => {
    if (!message.read && message.senderType === "User") {
      await markMessagesAsRead(message?._id);
      // Update unread count
      setUnreadCounts(prev => ({
        ...prev,
        [selectedConversation?._id]: Math.max(0, (prev[selectedConversation?._id] || 0) - 1)
      }));
    }
  };

  // Handle conversation selection
  const handleConversationSelect = (conv) => {
    setSelectedConversation(conv);
    // Reset unread count for this conversation
    setUnreadCounts(prev => ({
      ...prev,
      [conv?._id]: 0
    }));
  };

  const handleNewMessage = (data) => {
    setConversations((prevConversations) => {
      const updatedConversations = prevConversations.map((conv) => {
        if (conv.userId?._id === data.senderId) {
          return {
            ...conv,
            lastMessage: data.message,
            lastMessageTime: new Date().toISOString(),
            hasUnread: true
          };
        }
        return conv;
      });

      const conversationIndex = updatedConversations.findIndex(
        (conv) => conv.userId?._id === data.senderId
      );
      if (conversationIndex !== -1) {
        const [updatedConv] = updatedConversations.splice(conversationIndex, 1);
        updatedConversations.unshift(updatedConv);
      }

      return updatedConversations;
    });

    if (selectedConversation?.userId?._id === data.senderId) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          _id: Date.now(),
          senderId: data.senderId,
          senderType: "User",
          message: data.message,
          createdAt: new Date().toISOString(),
          read: false
        },
      ]);
    }
  };

  const handleConversationUpdate = (data) => {
    setConversations((prevConversations) => {
      const updatedConversations = prevConversations.map((conv) => {
        if (conv?._id === data.conversationId) {
          return {
            ...conv,
            lastMessage: data.lastMessage,
            lastMessageTime: data.lastMessageTime,
          };
        }
        return conv;
      });

      const conversationIndex = updatedConversations.findIndex(
        (conv) => conv?._id === data.conversationId
      );
      if (conversationIndex !== -1) {
        const [updatedConv] = updatedConversations.splice(conversationIndex, 1);
        updatedConversations.unshift(updatedConv);
      }

      return updatedConversations;
    });
  };

  // Scroll to bottom when new messages arrive
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Fetch conversations
  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/v1/conversations`);
        const data = await response.json();
        if (data.success) {
          setConversations(data.data);
        }
      } catch (error) {
        console.error("Error fetching conversations:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchConversations();
  }, []);

  // Fetch messages for selected conversation
  useEffect(() => {
    const fetchMessages = async () => {
      if (selectedConversation) {
        try {
          const response = await fetch(
            `${BACKEND_URL}/api/v1/messages/${selectedConversation?._id}`
          );
          const data = await response.json();
          if (data.success) {
            setMessages(data.data);
          }
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };
    fetchMessages();
  }, [selectedConversation]);

  // Send message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !selectedConversation) return;

    try {
      const response = await fetch(`${BACKEND_URL}/api/v1/messages/respond`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          senderId: localStorage.getItem("userId"),
          conversationId: selectedConversation?._id,
          message: newMessage,
        }),
      });
      const data = await response.json();
      if (data.success) {
        setMessages((prevMessages) => [...prevMessages, data.data]);
        setNewMessage("");

        socketRef.current.emit("admin_response", {
          type: "admin_response",
          conversationId: selectedConversation?._id,
          message: newMessage,
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const filteredConversations = conversations.filter(
    (conv) =>
      conv?._id && conv.userId && (conv.userId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        conv.lastMessage?.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      {/* Sidebar */}

      <div className="w-80 flex flex-col border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
        {/* Search Bar */}
        <div className="flex justify-center mt-4">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center w-[150px] justify-center gap-2 px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-lg shadow-md transition-transform transform hover:scale-105"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back
        </button>
        </div>
        <div className="p-4 border-b dark:border-gray-700">
          <div className="relative">
            <input
              type="text"
              placeholder="Search conversations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 pl-10 rounded-xl bg-gray-100 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 border-0"
            />
            <Search className="w-5 h-5 absolute left-3 top-2.5 text-gray-400" />
          </div>
        </div>

        {/* Conversations List */}
        <div className="flex-1 overflow-y-auto">
          {filteredConversations.map((conv) => (
            <div
              key={conv?._id}
              className={`p-4 cursor-pointer transition-colors duration-150 hover:bg-gray-50 dark:hover:bg-gray-700 ${selectedConversation?._id === conv?._id
                ? "bg-gray-100 dark:bg-gray-600"
                : ""
                }`}
              onClick={() => handleConversationSelect(conv)}
            >
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <div className="w-12 h-12 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                    <User className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex justify-between items-start">
                    <p className={`font-medium ${unreadCounts[conv?._id] ? 'text-blue-600 dark:text-blue-400' : 'text-gray-900 dark:text-gray-100'} truncate`}>
                      {conv.userId?.name || "Unknown User"}
                    </p>
                    <div className="flex items-center space-x-2">
                      {unreadCounts[conv?._id] > 0 && (
                        <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                          {unreadCounts[conv?._id]}
                        </span>
                      )}
                      <span className="text-xs text-gray-500 whitespace-nowrap">
                        {formatDistanceToNow(new Date(conv.lastMessageTime), {
                          addSuffix: true,
                        })}
                      </span>
                    </div>
                  </div>
                  <p className={`text-sm ${unreadCounts[conv?._id] ? 'font-semibold text-gray-800 dark:text-gray-300' : 'text-gray-600 dark:text-gray-400'} truncate mt-1`}>
                    {conv.lastMessage || "No messages yet"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Chat Panel */}
      <div className="flex-1 flex flex-col">
        {/* Chat Header */}
        <div className="p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
                <User className="w-6 h-6 text-gray-500 dark:text-gray-400" />
              </div>
              <div>
                <h2 className="font-semibold text-gray-900 dark:text-gray-100">
                  {selectedConversation?.userId?.name || "Select a Conversation"}
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50 dark:bg-gray-900">
          {messages.map((msg) => (
            <div
              key={msg?._id}
              className={`flex ${msg.senderType === "Admin" ? "justify-end" : "justify-start"
                }`}
              onMouseEnter={() => handleMessageVisible(msg)}
            >
              <div className={`max-w-[70%]`}>
                <div
                  className={`px-4 py-2 rounded-2xl ${msg.senderType === "Admin"
                    ? "bg-blue-500 text-white rounded-br-none"
                    : `${!msg.read ? "bg-blue-50 dark:bg-blue-900" : "bg-white dark:bg-gray-700"} dark:text-gray-100 rounded-bl-none`
                    }`}
                >
                  {msg.message}
                </div>
                <div className="flex items-center justify-end space-x-1 mt-1">
                  <p className="text-xs text-gray-500">
                    {formatDistanceToNow(new Date(msg.createdAt), {
                      addSuffix: true,
                    })}
                  </p>
                  {msg.senderType === "Admin" && (
                    <span className="text-gray-500">
                      {msg.read ? <CheckCheck className="w-4 h-4" /> : <Check className="w-4 h-4" />}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {/* Message Input */}
        {selectedConversation && <form
          className="p-4 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
          onSubmit={handleSendMessage}
        >
          <div className="flex items-center space-x-4">
            <input
              type="text"
              placeholder="Type a message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              className="flex-1 px-4 py-2 bg-gray-100 dark:bg-gray-700 border-0 rounded-xl focus:ring-2 focus:ring-blue-500 dark:text-gray-200"
            />
            <button
              type="submit"
              className="p-3 bg-blue-500 hover:bg-blue-600 text-white rounded-xl transition-colors duration-150"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </form>}
      </div>
    </div>
  );
};

export default AdminMessageDashboard;


// import React, { useState, useEffect, useRef } from "react";
// import { formatDistanceToNow } from "date-fns";
// import { Send, Search, Loader, User } from "lucide-react";
// import { io } from "socket.io-client";

// const BACKEND_URL = "https://kiwii.shop:5000";

// const AdminMessageDashboard = () => {
//   const [conversations, setConversations] = useState([]);
//   const [selectedConversation, setSelectedConversation] = useState(null);
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isLoading, setIsLoading] = useState(true);
//   const messagesEndRef = useRef(null);
//   const pollingIntervalRef = useRef(null);

//   const fetchConversations = async () => {
//     try {
//       const response = await fetch(`${BACKEND_URL}/api/v1/conversations`);
//       const data = await response.json();
//       if (data.success) {
//         setConversations(data.data);
//       }
//     } catch (error) {
//       console.error("Error fetching conversations:", error);
//     }
//   };

//   const fetchMessages = async () => {
//     if (selectedConversation) {
//       try {
//         const response = await fetch(
//           `${BACKEND_URL}/api/v1/messages/${selectedConversation?._id}`
//         );
//         const data = await response.json();
//         if (data.success) {
//           setMessages(data.data);
//         }
//       } catch (error) {
//         console.error("Error fetching messages:", error);
//       }
//     }
//   };

//   const startPolling = () => {
//     pollingIntervalRef.current = setInterval(() => {
//       fetchConversations();
//       fetchMessages();
//     }, 2000); // Fetch every 2 seconds
//   };

//   const stopPolling = () => {
//     if (pollingIntervalRef.current) {
//       clearInterval(pollingIntervalRef.current);
//     }
//   };

//   useEffect(() => {
//     setIsLoading(true);
//     fetchConversations();
//     setIsLoading(false);

//     startPolling();
//     return () => stopPolling();
//   }, []);

//   useEffect(() => {
//     fetchMessages();
//   }, [selectedConversation]);

//   const handleSendMessage = async (e) => {
//     e.preventDefault();
//     if (!newMessage.trim() || !selectedConversation) return;

//     try {
//       const response = await fetch(`${BACKEND_URL}/api/v1/messages/respond`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           senderId: localStorage.getItem("userId"),
//           conversationId: selectedConversation?._id,
//           message: newMessage,
//         }),
//       });
//       const data = await response.json();
//       if (data.success) {
//         setMessages((prevMessages) => [...prevMessages, data.data]);
//         setNewMessage("");
//       }
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//   };

//   const filteredConversations = conversations.filter(
//     (conv) =>
//       conv.userId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       conv.lastMessage?.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   if (isLoading) {
//     return (
//       <div className="flex h-screen items-center justify-center">
//         <Loader className="w-8 h-8 animate-spin text-blue-500" />
//       </div>
//     );
//   }

//   return (
//     <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
//       {/* Sidebar */}
//       <div className="w-80 flex flex-col border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
//         {/* Search Bar */}
//         <div className="p-4 border-b dark:border-gray-700">
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search conversations..."
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               className="w-full px-4 py-2 pl-10 rounded-xl bg-gray-100 dark:bg-gray-700 dark:text-gray-200 focus:ring-2 focus:ring-blue-500 border-0"
//             />
//             <Search className="w-5 h-5 absolute left-3 top-2.5 text-gray-400" />
//           </div>
//         </div>

//         {/* Conversations List */}
//         <div className="flex-1 overflow-y-auto">
//           {filteredConversations.map((conv) => (
//             <div
//               key={conv?._id}
//               className={`p-4 cursor-pointer transition-colors duration-150 hover:bg-gray-50 dark:hover:bg-gray-700 ${
//                 selectedConversation??._id === conv?._id
//                   ? "bg-gray-100 dark:bg-gray-600"
//                   : ""
//               }`}
//               onClick={() => setSelectedConversation(conv)}
//             >
//               <div className="flex items-center space-x-3">
//                 <div className="relative">
//                   <div className="w-12 h-12 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
//                     <User className="w-6 h-6 text-gray-500 dark:text-gray-400" />
//                   </div>
//                   <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-green-500 rounded-full border-2 border-white dark:border-gray-800"></div>
//                 </div>
//                 <div className="flex-1 min-w-0">
//                   <div className="flex justify-between items-start">
//                     <p className="font-medium text-gray-900 dark:text-gray-100 truncate">
//                       {conv.userId?.name || "Unknown User"}
//                     </p>
//                     <span className="text-xs text-gray-500 whitespace-nowrap ml-2">
//                       {formatDistanceToNow(new Date(conv.lastMessageTime), {
//                         addSuffix: true,
//                       })}
//                     </span>
//                   </div>
//                   <p className="text-sm text-gray-600 dark:text-gray-400 truncate mt-1">
//                     {conv.lastMessage || "No messages yet"}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Chat Panel */}
//       <div className="flex-1 flex flex-col">
//         {/* Chat Header */}
//         <div className="p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
//           <div className="flex items-center justify-between">
//             <div className="flex items-center space-x-3">
//               <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
//                 <User className="w-6 h-6 text-gray-500 dark:text-gray-400" />
//               </div>
//               <div>
//                 <h2 className="font-semibold text-gray-900 dark:text-gray-100">
//                   {selectedConversation?.userId?.name || "Select a Conversation"}
//                 </h2>
//                 <p className="text-sm text-green-500">Online</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Messages */}
//         <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50 dark:bg-gray-900">
//           {messages.map((msg) => (
//             <div
//               key={msg?._id}
//               className={`flex ${
//                 msg.senderType === "Admin" ? "justify-end" : "justify-start"
//               }`}
//             >
//               <div className={`max-w-[70%]`}>
//                 <div
//                   className={`px-4 py-2 rounded-2xl ${
//                     msg.senderType === "Admin"
//                       ? "bg-blue-500 text-white rounded-br-none"
//                       : "bg-white dark:bg-gray-700 dark:text-gray-100 rounded-bl-none"
//                   }`}
//                 >
//                   {msg.message}
//                 </div>
//                 <p className="text-xs text-gray-500 mt-1">
//                   {formatDistanceToNow(new Date(msg.createdAt), {
//                     addSuffix: true,
//                   })}
//                 </p>
//               </div>
//             </div>
//           ))}
//           <div ref={messagesEndRef} />
//         </div>

//         {/* Message Input */}
//         <form
//           className="p-4 border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
//           onSubmit={handleSendMessage}
//         >
//           <div className="flex items-center space-x-4">
//             <input
//               type="text"
//               placeholder="Type a message..."
//               value={newMessage}
//               onChange={(e) => setNewMessage(e.target.value)}
//               className="flex-1 px-4 py-2 bg-gray-100 dark:bg-gray-700 border-0 rounded-xl focus:ring-2 focus:ring-blue-500 dark:text-gray-200"
//             />
//             <button
//               type="submit"
//               className="p-3 bg-blue-500 hover:bg-blue-600 text-white rounded-xl transition-colors duration-150"
//             >
//               <Send className="w-5 h-5" />
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AdminMessageDashboard;
