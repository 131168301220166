import React, { useState, useEffect } from "react";
import CampaignTable from "../components/model-&-table/CampaignTable";
import CampaignModel from "../components/model-&-table/CampaignModel";
import { Moon, Sun } from "lucide-react";
import { LISTCAMPAIGN_API } from "../utils/constant";
import LoadingSkeleton from "../components/LoadingSkeleton";
import { countryList } from "../utils/countries";

const CampaignDashboard = () => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [newCampaign, setNewCampaign] = useState({
    title: "",
    subtitle: "",
    description: "",
    graphic_main: "",
    graphic_secondary: "",
    brandId: "",
    couponDisplayDate: "",
    couponExpiryDate: "",
    availableCountries: [],
    totalCoupons: 0,
    remainingCoupons: 0,
    winPercentage: 0,
    status: "draft",
    engagementStats: {
      impressions: 0,
      interactions: 0,
      redemptions: 0
    },
    couponCode:''
  });
  const [currentCampaign, setCurrentCampaign] = useState(null);

  const fetchTable = async () => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams({
        page: currentPage,
        limit: 10,
        ...(statusFilter && { status: statusFilter }),
        ...(countryFilter && { country: countryFilter }),
        ...(searchTerm && { search: searchTerm })
      });

      const response = await fetch(`${LISTCAMPAIGN_API}?${queryParams}`);
      if (!response.ok) throw new Error('Failed to fetch campaigns');

      const data = await response.json();
      setCampaigns(data.campaigns);
      setFilteredCampaigns(data.campaigns);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTable();
  }, [currentPage, statusFilter, countryFilter]);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchTerm) {
        fetchTable();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleCountryFilterChange = (e) => {
    setCountryFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleAddCampaign = async () => {
    try {
      const response = await fetch(LISTCAMPAIGN_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newCampaign)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add campaign');
      }

      setNewCampaign({
        title: "",
        subtitle: "",
        description: "",
        graphic_main: "",
        graphic_secondary: "",
        brandId: "",
        couponDisplayDate: "",
        couponExpiryDate: "",
        availableCountries: [],
        totalCoupons: 0,
        remainingCoupons: 0,
        winPercentage: 0,
        status: "draft",
        engagementStats: {
          impressions: 0,
          interactions: 0,
          redemptions: 0
        }
      });
      setIsAddDialogOpen(false);
      await fetchTable();
    } catch (error) {
      console.error("Error adding campaign:", error);
      alert(error.message);
    }
  };

  const handleEditCampaign = async () => {
    if (!currentCampaign._id) return;

    try {
      const response = await fetch(`${LISTCAMPAIGN_API}/${currentCampaign._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(currentCampaign)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update campaign');
      }

      setCurrentCampaign(null);
      setIsEditDialogOpen(false);
      await fetchTable();
    } catch (error) {
      console.error("Error updating campaign:", error);
      alert(error.message);
    }
  };

  const handleDeleteCampaign = async (campaignId) => {
    if (!window.confirm('Are you sure you want to delete this campaign?')) return;

    try {
      const response = await fetch(`${LISTCAMPAIGN_API}/${campaignId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete campaign');
      }

      await fetchTable();
    } catch (error) {
      console.error("Error deleting campaign:", error);
      alert(error.message);
    }
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center gap-4">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Scratch CardDashboard</h1>
            <button 
              onClick={toggleTheme} 
              className="p-2 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
              aria-label="Toggle theme"
            >
              {theme === 'dark' ? 
                <Sun className="w-5 h-5 text-gray-900 dark:text-white" /> : 
                <Moon className="w-5 h-5 text-gray-900 dark:text-white" />
              }
            </button>
          </div>
          <button
            className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg font-medium transition-colors duration-200"
            onClick={() => setIsAddDialogOpen(true)}
          >
            Add Scratch Card
          </button>
        </div>

        <div className="space-y-4 mb-8">
          <input
            type="text"
            placeholder="Search Scratch Cards..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full p-3 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-green-500 focus:border-transparent transition-colors duration-200"
          />

          <div className="flex flex-wrap gap-4">
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className="p-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-green-500 focus:border-transparent transition-colors duration-200"
            >
              <option value="">All Status</option>
              <option value="draft">Draft</option>
              <option value="scheduled">Scheduled</option>
              <option value="active">Active</option>
              <option value="paused">Paused</option>
              <option value="completed">Completed</option>
              <option value="expired">Expired</option>
            </select>

            <select
              value={countryFilter}
              onChange={handleCountryFilterChange}
              className="p-2 border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-green-500 focus:border-transparent transition-colors duration-200"
            >
              <option value="">All Countries</option>
              {countryList.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg transition-colors duration-200">
          <CampaignTable
            campaigns={filteredCampaigns}
            fetchTable={fetchTable}
            onEdit={(campaign) => {
              setCurrentCampaign(campaign);
              setIsEditDialogOpen(true);
            }}
            onDelete={handleDeleteCampaign}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>

        <CampaignModel
          isOpen={isAddDialogOpen}
          onClose={() => setIsAddDialogOpen(false)}
          campaign={newCampaign}
          setCampaign={setNewCampaign}
          onSubmit={handleAddCampaign}
          isEditMode={false}
          fetchTable={fetchTable}
        />

        {isEditDialogOpen && (
          <CampaignModel
            isOpen={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            campaign={currentCampaign}
            setCampaign={setCurrentCampaign}
            onSubmit={handleEditCampaign}
            isEditMode={true}
            fetchTable={fetchTable}
          />
        )}

        <div className="mt-6 flex justify-center gap-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`px-4 py-2 rounded-lg font-medium transition-colors duration-200 ${
                currentPage === page
                  ? 'bg-green-600 text-white'
                  : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700'
              }`}
            >
              {page}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignDashboard;