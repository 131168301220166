import React, { useState, useMemo, useEffect } from "react";
import { AiOutlineInteraction } from "react-icons/ai";
import { SiBrandfolder, SiCampaignmonitor } from "react-icons/si";
import { Search, Filter, Pencil, Trash2 } from "lucide-react";
import { toast } from "react-toastify";
import { DELETE_PRIZE_API, LISTPRIZE_API, UPDATE_PRICE_API } from "../utils/constant";
import LoadingSkeleton from "../components/LoadingSkeleton";
import { format } from "date-fns";

const PrizeDashboard = () => {
  const [statusFilter, setStatusFilter] = useState("all");
  const [couponCodeFilter, setCouponCodeFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingCampaign, setEditingCampaign] = useState(null);
  const [deletingCampaign, setDeletingCampaign] = useState(null);

  const fetchTable = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(LISTPRIZE_API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCampaigns(data);
    } catch (err) {
      setError(err.message);
      toast.error("Failed to fetch campaigns");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTable();
  }, []);

  const uniqueCouponCodes = useMemo(() => {
    return ["all", ...new Set(campaigns.map((campaign) => campaign.couponCode))];
  }, [campaigns]);

  const filteredCampaigns = useMemo(() => {
    return campaigns.filter((campaign) => {
      const matchesStatus =
        statusFilter === "all" || campaign.status === statusFilter;
      const matchesCouponCode =
        couponCodeFilter === "all" || campaign.couponCode === couponCodeFilter;
      const matchesSearch =
        searchTerm === "" ||
        campaign?.campaignId?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign?.couponCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign?.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        campaign?.winPercentage?.toString().includes(searchTerm);

      return matchesStatus && matchesCouponCode && matchesSearch;
    });
  }, [campaigns, statusFilter, couponCodeFilter, searchTerm]);

  const handleEditCampaign = async () => {
    if (!editingCampaign) return;

    try {
      
      const response = await fetch(`${UPDATE_PRICE_API}/${editingCampaign._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editingCampaign),
      });
      
      if (!response.ok) throw new Error('Failed to update campaign');
      
      setCampaigns(
        campaigns.map((campaign) =>
          campaign.id === editingCampaign.id ? editingCampaign : campaign
        )
      );
      toast.success("Campaign updated successfully!");
    } catch (err) {
      toast.error("Failed to update campaign");
    } finally {
      setEditingCampaign(null);
    }
  };

  const handleDeleteCampaign = async () => {
    if (!deletingCampaign) return;

    try {
      const response = await fetch(`${DELETE_PRIZE_API}/${deletingCampaign._id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();
      setCampaigns(campaigns.filter(campaign => campaign.id !== deletingCampaign.id));
      
      toast.success("Deleted Successfully!", {
        style: {
          backgroundColor: "#395b06",
          color: "#fff",
          fontSize: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      });
    } catch (err) {
      toast.error("Failed to delete campaign");
    } finally {
      setDeletingCampaign(null);
    }
  };

  if (loading) {
    return  <LoadingSkeleton/>
  }

  if (error) {
    return <div className="p-6 text-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="p-6 mx-auto py-24 bg-gray-200 dark:bg-gray-900 min-h-screen">
      {/* Stats Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-blue-50 dark:bg-gray-800 rounded-lg shadow p-4 flex items-center justify-start gap-6 transition-transform duration-300 hover:scale-105">
          <SiCampaignmonitor className="text-3xl text-gray-600 dark:text-gray-300" />
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              {campaigns.length}
            </h1>
            <p className="text-sm text-gray-600 dark:text-gray-400">Campaigns</p>
          </div>
        </div>
        <div className="bg-pink-50 dark:bg-gray-800 rounded-lg shadow p-4 flex items-center justify-start gap-6 transition-transform duration-300 hover:scale-105">
          <SiBrandfolder className="text-3xl text-gray-600 dark:text-gray-300" />
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              {uniqueCouponCodes.length - 1}
            </h1>
            <p className="text-sm text-gray-600 dark:text-gray-400">Coupon Codes</p>
          </div>
        </div>
        <div className="bg-red-50 dark:bg-gray-800 rounded-lg shadow p-4 flex items-center justify-start gap-6 transition-transform duration-300 hover:scale-105">
          <AiOutlineInteraction className="text-3xl text-gray-600 dark:text-gray-300" />
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              {campaigns.reduce((total, campaign) => 
                total + (campaign.redemptionStats?.total || 0), 0)}
            </h1>
            <p className="text-sm text-gray-600 dark:text-gray-400">Total Interactions</p>
          </div>
        </div>
      </div>

      {/* Search and Filters Section */}
      <div className="relative w-full flex flex-col gap-6 mb-6">
        <div className="w-full flex items-center relative">
          <input
            type="text"
            placeholder="Search campaigns..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-16 py-3 border border-gray-300 dark:border-gray-600 hover:border-green-800 dark:hover:border-green-600 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-green-800 dark:bg-gray-800 dark:text-white"
          />
          <Search className="absolute left-3 top-3.5 h-5 w-5 text-gray-400 dark:text-gray-500" />
          <button 
            onClick={() => setSearchTerm("")}
            className="absolute right-2 px-6 py-2 bg-green-800 dark:bg-green-600 text-white rounded-lg hover:bg-green-700 dark:hover:bg-green-500 transition-colors"
          >
            Clear
          </button>
        </div>

        <div className="absolute right-2 top-[60px]">
          <button className="flex items-center gap-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
            <Filter className="h-6 w-6" />
            <span className="text-sm">Filter</span>
          </button>
        </div>
      </div>

      {/* Filter Dropdowns */}
      <div className="flex flex-col md:flex-row gap-4">
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="w-full md:w-48 p-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-800 dark:bg-gray-800 dark:text-white"
        >
          <option value="all">All Status</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>

        <select
          value={couponCodeFilter}
          onChange={(e) => setCouponCodeFilter(e.target.value)}
          className="w-full md:w-48 p-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-800 dark:bg-gray-800 dark:text-white"
        >
          {uniqueCouponCodes.map((couponCode) => (
            <option key={couponCode} value={couponCode}>
              {couponCode === "all" ? "All Coupon Codes" : couponCode}
            </option>
          ))}
        </select>
      </div>

      {/* Campaign Table */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto mt-6">
        <table className="min-w-full">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th className="w-12 py-3 px-4">
                <input type="checkbox" className="rounded dark:bg-gray-600 dark:border-gray-500" />
              </th>
              <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">Campaign Name</th>
              <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">Status</th>
              <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">Redemption Stats</th>
              <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">Coupon Code</th>
              <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">Coupon QR</th>
              <th className="text-left py-3 px-4 text-gray-600 dark:text-gray-300">Created On</th>
              <th className="w-12 py-3 px-4 text-gray-600 dark:text-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredCampaigns.map((campaign) => (
              <tr key={campaign.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                <td className="py-3 px-4">
                  <input type="checkbox" className="rounded dark:bg-gray-600 dark:border-gray-500" />
                </td>
                <td className="py-3 px-4">
                  <div className="flex items-center gap-2">
                    <div className="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-600"></div>
                    <span className="text-gray-900 dark:text-white">{campaign?.campaignId?.name}</span>
                  </div>
                </td>
                <td className="py-3 px-4">
                  <span
                    className={`px-2 py-1 rounded-full text-sm ${
                      campaign.status === "Active"
                        ? "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400"
                        : "bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-400"
                    }`}
                  >
                    {campaign.status}
                  </span>
                </td>
                <td className="py-3 px-4 text-gray-900 dark:text-white">
                  {campaign.redemptionStats?.redeemed || 0}/{campaign.redemptionStats?.total || 0}
                </td>
                <td className="py-3 px-4 text-gray-900 dark:text-white">{campaign.couponCode}</td>
                <td className="py-3 px-4 text-gray-900 dark:text-white">{campaign.qrCode}</td>
                <td className="py-3 px-4 text-gray-900 dark:text-white">{format(new Date(campaign.createdAt), "dd/MM/yyyy")}</td>
                <td className="py-3 px-4 flex gap-2">
                  <button
                    onClick={() => setEditingCampaign(campaign)}
                    className="text-blue-500 hover:bg-blue-50 dark:text-blue-400 dark:hover:bg-blue-900/20 p-1.5 rounded"
                  >
                    <Pencil className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => setDeletingCampaign(campaign)}
                    className="text-red-500 hover:bg-red-50 dark:text-red-400 dark:hover:bg-red-900/20 p-1.5 rounded"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Campaign Modal */}
      {editingCampaign && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">Edit Campaign</h2>
            <div className="space-y-4">
              <input
                type="text"
                value={editingCampaign.name}
                onChange={(e) =>
                  setEditingCampaign({
                    ...editingCampaign,
                    name: e.target.value,
                  })
                }
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700 dark:text-white"
                placeholder="Campaign Name"
              />
              <select
                value={editingCampaign.status}
                onChange={(e) =>
                  setEditingCampaign({
                    ...editingCampaign,
                    status: e.target.value,
                  })
                }
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700 dark:text-white"
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
              <input
                type="text"
                value={editingCampaign.couponCode}
                onChange={(e) =>
                  setEditingCampaign({
                    ...editingCampaign,
                    couponCode: e.target.value,
                  })
                }
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700 dark:text-white"
                placeholder="Coupon Code"
              />
              <input
                type="text"
                value={editingCampaign.qrCode}
                onChange={(e) =>
                  setEditingCampaign({
                    ...editingCampaign,
                    qrCode: e.target.value,
                  })
                }
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700 dark:text-white"
                placeholder="QR Code"
              />
              <input
                type="text"
                value={editingCampaign.winPercentage}
                onChange={(e) =>
                  setEditingCampaign({
                    ...editingCampaign,
                    winPercentage: e.target.value,
                  })
                }
                className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700 dark:text-white"
                placeholder="Win%"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setEditingCampaign(null)}
                  className="px-4 py-2 bg-gray-200 dark:bg-gray-600 rounded text-gray-800 dark:text-white"
                >
                  Cancel
                </button>
                <button
                  onClick={handleEditCampaign}
                  className="px-4 py-2 bg-green-600 text-white rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {deletingCampaign && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-700 p-6 rounded-lg w-96">
            <h2 className="text-xl dark:text-yellow-100 font-bold mb-4">Confirm Delete</h2>
            <p className="mb-4 dark:text-yellow-100">
              Are you sure you want to delete the campaign "
              {deletingCampaign.name}"?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setDeletingCampaign(null)}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteCampaign}
                className="px-4 py-2 bg-red-600 text-white rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrizeDashboard;
