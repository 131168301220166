import React from 'react';

const LoadingSkeleton = () => {
  return (
    <div className="p-6 space-y-4 w-full dark:bg-gray-900 dark:text-white min-h-screen">
      {/* Status Boxes */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="h-24 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse p-4">
          <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded mb-2" />
          <div className="h-8 w-16 bg-gray-300 dark:bg-gray-600 rounded mt-4" />
        </div>
        <div className="h-24 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse p-4">
          <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded mb-2" />
          <div className="h-8 w-16 bg-gray-300 dark:bg-gray-600 rounded mt-4" />
        </div>
        <div className="h-24 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse p-4">
          <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded mb-2" />
          <div className="h-8 w-16 bg-gray-300 dark:bg-gray-600 rounded mt-4" />
        </div>
      </div>
      
      {/* Search Bar Skeleton */}
      <div className="flex gap-4 items-center">
        <div className="flex-1 h-10 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse" />
      </div>
      
      {/* Dropdowns Skeleton */}
      <div className="flex gap-4">
        <div className="w-48 h-10 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse" />
        <div className="w-48 h-10 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse" />
      </div>
      
      {/* Table Skeleton */}
      <div className="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
        {/* Table Header */}
        <div className="bg-gray-100 dark:bg-gray-800">
          <div className="flex">
            {[1, 2, 3, 4].map((i) => (
              <div key={i} className="flex-1 p-4">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
              </div>
            ))}
          </div>
        </div>
        
        {/* Table Rows */}
        {[1, 2, 3, 4, 5].map((row) => (
          <div key={row} className="border-t border-gray-200 dark:border-gray-700">
            <div className="flex">
              {[1, 2, 3, 4].map((col) => (
                <div key={col} className="flex-1 p-4">
                  <div className="h-4 bg-gray-100 dark:bg-gray-600 rounded animate-pulse" />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadingSkeleton;