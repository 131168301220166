import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Users,
  ShoppingBag,
  Gift,
  MessageCircle,
  Bell,
  TrendingUp,
  Activity,
  Moon,
  Sun
} from 'lucide-react';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

// Dashboard Card Component remains the same
const DashboardCard = ({
  icon: Icon,
  title,
  value,
  change,
  changeType = 'positive',
  backgroundColor = 'bg-white dark:bg-gray-800',
  textColor = 'text-gray-800 dark:text-gray-200'
}) => {
  return (
    <div className={`${backgroundColor} rounded-2xl shadow-lg p-3 transform transition-all duration-300 hover:scale-105 hover:shadow-xl`}>
      <div className="flex justify-between items-center mb-4">
        <div className={`p-3 rounded-full ${changeType === 'positive' ? 'bg-green-100 dark:bg-green-900/30' : 'bg-red-100 dark:bg-red-900/30'}`}>
          <Icon className={`${changeType === 'positive' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'} w-6 h-6`} />
        </div>
        <div className={`text-sm font-medium ${changeType === 'positive' ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
          {change}
        </div>
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-1">{title}</h3>
        <div className={`text-2xl font-bold ${textColor}`}>{value}</div>
      </div>
    </div>
  );
};

// Chart Section Component remains the same
const ChartSection = ({ campaignData, prizeData }) => {
  return (
    <div className="grid md:grid-cols-2 gap-6 mt-8">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Campaign Performance</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={campaignData}>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke="gray"
              className="opacity-20 dark:opacity-30"
            />
            <XAxis
              dataKey="name"
              className="text-gray-600 dark:text-gray-300"
            />
            <YAxis
              className="text-gray-600 dark:text-gray-300"
            />
            <Tooltip
              contentStyle={{
                backgroundColor: 'rgb(31, 41, 55)',
                color: 'white',
                borderRadius: '0.5rem'
              }}
            />
            <Line
              type="monotone"
              dataKey="participants"
              stroke="#8884d8"
              strokeWidth={3}
              dot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4">Prize Distribution</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={prizeData}>
            <CartesianGrid
              strokeDasharray="3 3"
              stroke="gray"
              className="opacity-20 dark:opacity-30"
            />
            <XAxis
              dataKey="name"
              className="text-gray-600 dark:text-gray-300"
            />
            <YAxis
              className="text-gray-600 dark:text-gray-300"
            />
            <Tooltip
              contentStyle={{
                backgroundColor: 'rgb(31, 41, 55)',
                color: 'white',
                borderRadius: '0.5rem'
              }}
            />
            <Bar
              dataKey="amount"
              fill="#82ca9d"
              radius={[10, 10, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

// Main Dashboard Component
const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    users: [],
    brands: [],
    prizes: [],
    campaigns: [],
    messages: [],
    notifications: []
  });

  // Sample campaign and prize data
  const campaignData = [
    { name: 'Jan', participants: 400 },
    { name: 'Feb', participants: 300 },
    { name: 'Mar', participants: 500 },
    { name: 'Apr', participants: 450 },
    { name: 'May', participants: 600 },
    { name: 'Jun', participants: 550 }
  ];

  const prizeData = [
    { name: 'Gold', amount: 50 },
    { name: 'Silver', amount: 100 },
    { name: 'Bronze', amount: 150 },
    { name: 'Special', amount: 75 }
  ];

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [
          usersResponse,
          brandsResponse,
          prizesResponse,
          campaignsResponse,
          messagesResponse,
          notificationsResponse
        ] = await Promise.all([
          fetch('https://kiwii.shop:5000/api/v1/admin/list-all-users'),
          fetch('https://kiwii.shop:5000/api/v1/brands'),
          fetch('https://kiwii.shop:5000/api/v1/prizes'),
          fetch('https://kiwii.shop:5000/api/v1/campaigns'),
          fetch('https://kiwii.shop:5000/api/v1/messages'),
          fetch('https://kiwii.shop:5000/api/v1/notifications/all')
        ]);

        const users = await usersResponse.json();
        const brands = await brandsResponse.json();
        const prizes = await prizesResponse.json();
        const campaigns = await campaignsResponse.json();
        const messages = await messagesResponse.json();
        const notifications = await notificationsResponse.json();

        console.log(users, brands, prizes, campaigns, '-->abhsihe')

        setDashboardData({
          users,
          brands: brands.brands,
          prizes,
          campaigns: campaigns?.campaigns,
          messages: messages?.data,
          notifications: notifications?.notifications
        });

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setIsLoading(false);
      }
    };

    if (location.state?.flag) {
      fetchDashboardData();
    }
  }, [location.state?.flag]);

  if (!location.state?.flag) {
    navigate('/welcome');
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  const dashboardStats = [
    {
      icon: Users,
      title: 'Total Users',
      value: dashboardData.users?.length || '0',
      change: '+12.5%',
      changeType: 'positive',
      backgroundColor: 'bg-blue-50 dark:bg-blue-900/20',
      textColor: 'text-blue-800 dark:text-blue-300'
    },
    {
      icon: ShoppingBag,
      title: 'Active Brands',
      value: dashboardData.brands?.length || '0',
      change: '+8.3%',
      changeType: 'positive',
      backgroundColor: 'bg-purple-50 dark:bg-purple-900/20',
      textColor: 'text-purple-800 dark:text-purple-300'
    },
    {
      icon: Gift,
      title: 'Total Prizes',
      value: dashboardData.prizes?.length || '0',
      change: '+15.7%',
      changeType: 'positive',
      backgroundColor: 'bg-green-50 dark:bg-green-900/20',
      textColor: 'text-green-800 dark:text-green-300'
    },
    {
      icon: Activity,
      title: 'Active Campaigns',
      value: dashboardData.campaigns?.length || '0',
      change: '+10.3%',
      changeType: 'positive',
      backgroundColor: 'bg-yellow-50 dark:bg-yellow-900/20',
      textColor: 'text-yellow-800 dark:text-yellow-300'
    },
    {
      icon: MessageCircle,
      title: 'Messages',
      value: dashboardData.messages?.length || '0',
      change: '+5.1%',
      changeType: 'positive',
      backgroundColor: 'bg-teal-50 dark:bg-teal-900/20',
      textColor: 'text-teal-800 dark:text-teal-300'
    },
    {
      icon: Bell,
      title: 'Notifications',
      value: dashboardData.notifications?.length || '0',
      change: '+7.3%',
      changeType: 'positive',
      backgroundColor: 'bg-red-50 dark:bg-red-900/20',
      textColor: 'text-red-800 dark:text-red-300'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">Dashboard</h1>
          <p className="text-gray-600 dark:text-gray-400">Welcome back! Here's an overview of your platform.</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {dashboardStats.map((stat, index) => (
            <DashboardCard
              key={index}
              icon={stat.icon}
              title={stat.title}
              value={stat.value}
              change={stat.change}
              changeType={stat.changeType}
              backgroundColor={stat.backgroundColor}
              textColor={stat.textColor}
            />
          ))}
        </div>

        <ChartSection
          campaignData={campaignData}
          prizeData={prizeData}
        />
      </div>
    </div>
  );
};

export default Dashboard;