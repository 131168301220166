import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationManagementPanel = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [userNotifications, setUserNotifications] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newNotification, setNewNotification] = useState({
    title: '',
    description: '',
  });
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [openUserNotificationsModal, setOpenUserNotificationsModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const [isLoadingUserNotifications, setIsLoadingUserNotifications] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchNotifications();
  }, []);

  const fetchUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const response = await axios.get('https://kiwii.shop:5000/api/v1/admin/list-all-users');
      setUsers(response.data);
      toast.success('Users loaded successfully');
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Failed to load users');
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const fetchNotifications = async () => {
    setIsLoadingNotifications(true);
    try {
      const response = await axios.get('https://kiwii.shop:5000/api/v1/notifications/all');
      setNotifications(response.data.notifications);
      toast.success('Notifications loaded successfully');
    } catch (error) {
      console.error('Error fetching notifications:', error);
      toast.error('Failed to load notifications');
    } finally {
      setIsLoadingNotifications(false);
    }
  };

  const fetchUserNotifications = async (userId) => {
    setIsLoadingUserNotifications(true);
    try {
      const response = await axios.get(`https://kiwii.shop:5000/api/v1/notifications/user/${userId}`);
      setUserNotifications(response.data.notifications);
      setSelectedUser(users.find(user => user?._id === userId));
      setOpenUserNotificationsModal(true);
      toast.success('User notifications loaded successfully');
    } catch (error) {
      console.error('Error fetching user notifications:', error);
      toast.error('Failed to load user notifications');
    } finally {
      setIsLoadingUserNotifications(false);
    }
  };

  const handleUserSelect = (userId, event) => {
    event.stopPropagation();
    if (isAllSelected) {
      setIsAllSelected(false);
      setSelectedUsers(selectedUsers.filter((e)=>e!=userId));
    } else {
      setSelectedUsers(prev => 
        prev.includes(userId) 
          ? prev.filter(id => id !== userId)
          : [...prev, userId]
      );
    }
  };

  const handleSelectAll = () => {
    setIsAllSelected(!isAllSelected);
    setSelectedUsers(isAllSelected ? [] : users.map(user => user?._id));
  };

  const handleSendNotification = async () => {
    setIsSendingNotification(true);
    try {
      if (isAllSelected) {
        await axios.post('https://kiwii.shop:5000/api/v1/notifications/send-to-all', newNotification);
        toast.success('Notification sent to all users');
      } else {
        await Promise.all(
          selectedUsers.map(userId => 
            axios.post(`https://kiwii.shop:5000/api/v1/notifications/send-to-user/${userId}`, newNotification)
          )
        );
        toast.success('Notification sent to selected users');
      }
      
      fetchNotifications();
      setNewNotification({ title: '', description: '' });
      setSelectedUsers([]);
      setIsAllSelected(false);
    } catch (error) {
      console.error('Error sending notifications:', error);
      toast.error('Failed to send notification');
    } finally {
      setIsSendingNotification(false);
    }
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 35) {
      setNewNotification(prev => ({ ...prev, title: value }));
    }
  };

  const filteredUsers = users.filter(user => 
    user?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user?.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto py-8 bg-gray-50 dark:bg-gray-900 min-h-screen transition-colors duration-200">
      <ToastContainer position="top-right" autoClose={3000} theme="colored" />
      
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800 dark:text-white mb-4 flex items-center justify-center gap-4">
          <span className="text-blue-600 dark:text-blue-400">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 1 0 01-3-3h6a3 1 0 01-3 3z" />
            </svg>
          </span>
          Notification Center
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Notification Creation Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 relative transition-all duration-300 hover:shadow-xl">
          {isSendingNotification && (
            <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80 rounded-xl">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
            </div>
          )}
          
          <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-6">Create Notification</h2>
          
          <div className="space-y-4">
            <div>
              <input
                type="text"
                placeholder="Notification Title"
                className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                value={newNotification.title}
                onChange={handleTitleChange}
                disabled={isSendingNotification}
              />
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                {newNotification.title.length}/35 characters
              </p>
            </div>

            <textarea
              placeholder="Notification Description"
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent h-32 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              value={newNotification.description}
              onChange={(e) => setNewNotification(prev => ({ ...prev, description: e.target.value }))}
              disabled={isSendingNotification}
            />

            <div className="flex items-center">
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={handleSelectAll}
                disabled={isSendingNotification}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-600 rounded"
              />
              <label className="ml-2 text-gray-700 dark:text-gray-300">Send to All Users</label>
            </div>

            <div className="flex flex-wrap gap-2">
              {selectedUsers.map(userId => {
                const user = users.find(u => u._id === userId);
                return (
                  <div key={userId} className="flex items-center bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-3 py-1 rounded-full">
                    <img
                      src={user?.profilePicture || 'https://static.vecteezy.com/system/resources/thumbnails/000/439/863/small_2x/Basic_Ui__28186_29.jpg'}
                      alt={user?.name}
                      className="w-6 h-6 rounded-full mr-2"
                    />
                    <span>{user?.name || 'Unknown User'}</span>
                    <button
                      onClick={() => handleUserSelect(userId, { stopPropagation: () => {} })}
                      className="ml-2 text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-200"
                      disabled={isSendingNotification}
                    >
                      ×
                    </button>
                  </div>
                );
              })}
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleSendNotification}
                disabled={!newNotification.title || !newNotification.description || 
                        (!isAllSelected && selectedUsers.length === 0) || isSendingNotification ||
                        newNotification.title.length > 35}
                className="bg-blue-600 dark:bg-blue-500 text-white px-8 py-3 rounded-lg font-semibold 
                         disabled:opacity-50 disabled:cursor-not-allowed
                         hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-200"
              >
                {isSendingNotification ? 'Sending...' : 'Send Notification'}
              </button>
            </div>
          </div>
        </div>

        {/* User Selection Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 relative">
          {isLoadingUsers && (
            <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80 rounded-xl">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
            </div>
          )}

          <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-6">Select Recipients</h2>

          <div className="relative mb-6">
            <input
              type="text"
              placeholder="Search users by name or email..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              disabled={isLoadingUsers}
            />
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400 dark:text-gray-500 absolute left-3 top-3" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
          </div>

          <div className="max-h-96 overflow-y-auto space-y-4 scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600">
            {filteredUsers.map(user => (
              <div
                key={user?._id}
                className={`bg-white dark:bg-gray-800 rounded-lg p-4 border-2 transition-all duration-200 hover:scale-102 hover:shadow-md
                          ${selectedUsers.includes(user?._id) ? 'border-blue-500 dark:border-blue-400' : 'border-transparent'}`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <img
                      src={user?.profilePicture || 'https://static.vecteezy.com/system/resources/thumbnails/000/439/863/small_2x/Basic_Ui__28186_29.jpg'}
                      alt={user?.name}
                      className="w-14 h-14 rounded-full"
                    />
                    <div>
                      <h3 className="font-semibold text-gray-800 dark:text-white">{user?.name}</h3>
                      <p className="text-gray-600 dark:text-gray-400">{user?.email}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user?._id)}
                      onChange={(e) => handleUserSelect(user?._id, e)}
                      disabled={isLoadingUsers}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-600 rounded"
                    />
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        fetchUserNotifications(user?._id);
                      }}
                      className="text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-200"
                      disabled={isLoadingUsers}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Notification History Section */}
      <div className="mt-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 relative">
        {isLoadingNotifications && (
          <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80 rounded-xl">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
          </div>
        )}

        <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-6">Notification History</h2>

        <div className="max-h-96 overflow-y-auto space-y-4 scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600">
          {notifications.map(notification => (
            <div key={notification._id} className="bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-700 rounded-lg shadow p-4">
              <h3 className="text-xl font-semibold text-blue-600 dark:text-blue-400">{notification.title}</h3>
              <p className="text-gray-600 dark:text-gray-300 mt-2">{notification.description}</p>
              <p className="text-right text-sm text-gray-500 dark:text-gray-400 mt-4 italic">
                {new Date(notification.createdAt).toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* User Notifications Modal */}
      {openUserNotificationsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-2xl">
            <div className="p-4 border-b dark:border-gray-700 flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <img
                  src={selectedUser?.profilePicture || '/api/placeholder/40/40'}
                  alt={selectedUser?.name}
                  className="w-10 h-10 rounded-full"
                />
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Notifications for {selectedUser?.name}
                </h2>
              </div>
              <button
                onClick={() => setOpenUserNotificationsModal(false)}
                className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="p-4 max-h-96 overflow-y-auto relative">
              {isLoadingUserNotifications ? (
                <div className="absolute inset-0 flex items-center justify-center bg-white dark:bg-gray-800 bg-opacity-80 dark:bg-opacity-80">
                  <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
                </div>
              ) : userNotifications.length === 0 ? (
                <p className="text-center text-gray-500 dark:text-gray-400">
                  No notifications found for this user
                </p>
              ) : (
                <div className="space-y-4">
                  {userNotifications.map(notification => (
                    <div key={notification._id} className="border-b dark:border-gray-700 pb-4">
                      <h3 className="text-lg font-semibold text-blue-600 dark:text-blue-400">
                        {notification.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300 mt-1">
                        {notification.description}
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                        {new Date(notification.createdAt).toLocaleString()}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationManagementPanel;