import React, { useState, useEffect, useRef } from "react";
import { Moon, Sun, Users, Activity, Award } from "lucide-react";
import { EmployeeDetailsModal } from "../components/model-&-table/EmployeeDetailsModel";
import { USER_STATUS_API, USERS_LIST_API } from "../utils/constant";
import LoadingSkeleton from "../components/LoadingSkeleton";
import { format } from "date-fns";
// SVG components remain unchanged...
const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-gray-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
);

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

const ViewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 text-gray-500 hover:text-green-600 cursor-pointer transition-colors"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);

const Dropdown = ({ options, value, onChange, isOpen, setIsOpen }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className="flex items-center gap-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 px-3 py-2 rounded-md bg-white hover:bg-gray-100 dark:bg-gray-700 w-[200px]"
      >
        {value}
        <ChevronDownIcon />
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 mt-1 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-md shadow-lg z-10 min-w-[200px]">
          {options.map((option) => (
            <button
              key={option}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
              className="block w-full text-left px-4 py-2 hover:bg-blue-200 dark:hover:bg-gray-800 text-gray-800 dark:text-gray-300"
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const StatsCard = ({
  icon: Icon,
  label,
  value,
  backgroundColor,
  textColor,
}) => (
  <div
    className={`${backgroundColor} rounded-2xl shadow-lg p-4 transform transition-all duration-300 hover:scale-105 hover:shadow-xl`}
  >
    <div className="flex items-center justify-between mb-2">
      <div className="p-2 rounded-full bg-white/20">
        <Icon className={`${textColor} w-6 h-6`} />
      </div>
      <div className="flex flex-col">
        <h3 className={`text-sm font-medium ${textColor}`}>{label}</h3>
        <div className={`text-2xl font-bold ${textColor}`}>{value}</div>
      </div>
    </div>
  </div>
);

const UserManagementDashboard = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [countryFilter, setCountryFilter] = useState("All countrys");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [viewedEmployee, setViewedEmployee] = useState(null);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [deptDropdownOpen, setDeptDropdownOpen] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );

  // Fetch employees data
  const fetchEmployees = async () => {
    try {
      setLoading(true);
      const response = await fetch(USERS_LIST_API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch employees");
      }

      const data = await response.json();
      setEmployees(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
   

    fetchEmployees();
  }, []);

  const viewsEmployeeDetails = async () => {
    try {
      const response = await fetch(USER_STATUS_API + viewedEmployee._id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: viewedEmployee.status === "active" ? "inactive" : "active",
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to update employee status");
      }
      fetchEmployees();
    } catch (error) {
      console.log(error);
    }
  };

  // Theme effect
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  // Memoize filtered and sorted employees
  const filteredEmployees = React.useMemo(() => {
    let result = [...employees];

    if (searchTerm) {
      result = result.filter((emp) =>
        Object.values(emp).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (statusFilter !== "All") {
      result = result.filter((emp) => emp.status === statusFilter);
    }

    if (countryFilter !== "All countrys") {
      result = result.filter((emp) => emp.country === countryFilter);
    }

    if (sortConfig.key) {
      result.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "asc" ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      });
    }

    return result;
  }, [employees, searchTerm, statusFilter, countryFilter, sortConfig]);

  const countries = React.useMemo(
    () => ["All countrys", ...new Set(employees.map((emp) => emp.country))],
    [employees]
  );

  const statuses = ["All", "active", "inactive"];

  const handleSort = (key) => {
    setSortConfig((current) => ({
      key,
      direction:
        current.key === key && current.direction === "asc" ? "desc" : "asc",
    }));
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center text-red-600">
        Error: {error}
      </div>
    );
  }

  return (
    <div className="min-h-screen py-16 bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">
            User Management
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            Manage and track your employees effectively.
          </p>
        </div>
        {/* Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <StatsCard
            icon={Users}
            label="Total Employees"
            value={employees.length}
            backgroundColor="bg-blue-50 dark:bg-blue-900/20"
            textColor="text-blue-800 dark:text-blue-300"
          />
          <StatsCard
            icon={Activity}
            label="Active Employees"
            value={employees.filter((emp) => emp.status === "active").length}
            backgroundColor="bg-green-50 dark:bg-green-900/20"
            textColor="text-green-800 dark:text-green-300"
          />
          <StatsCard
            icon={Award}
            label="Countries"
            value={new Set(employees.map((emp) => emp.country)).size}
            backgroundColor="bg-purple-50 dark:bg-purple-900/20"
            textColor="text-purple-800 dark:text-purple-300"
          />
        </div>
        {/* Search and Filters */}
        <div className="flex flex-col md:flex-row gap-4 mb-6">
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Search employees..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-700"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute left-3 top-1/2 -translate-y-1/2">
              <SearchIcon />
            </div>
          </div>

          <div className="flex gap-4">
            <Dropdown
              options={statuses}
              value={statusFilter}
              onChange={setStatusFilter}
              isOpen={statusDropdownOpen}
              setIsOpen={setStatusDropdownOpen}
            />
            <Dropdown
              options={countries}
              value={countryFilter}
              onChange={setCountryFilter}
              isOpen={deptDropdownOpen}
              setIsOpen={setDeptDropdownOpen}
            />
          </div>
        </div>
        {/* Table */}
        <div className="overflow-x-auto rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 no-scroll">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            {/* Table Header */}
            <thead className="bg-gray-50 dark:bg-gray-900">
              <tr>
                {[
                  "User",
                  "Status",
                  "Phone",
                  "Email",
                  "Role",
                  "Country",
                  "Registration Date",
                  "Verified",
                  "redemptions",
                  "wins",
                  "Actions",
                ].map((header, index) => (
                  <th
                    key={index}
                    onClick={() => {
                      const sortKeys = [
                        "name",
                        "status",
                        "phone",
                        "email",
                        "role",
                        "country",
                        "registrationDate",
                        "verified",
                        "redemptions",
                        "wins",
                      ];
                      if (sortKeys[index]) handleSort(sortKeys[index]);
                    }}
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-medium text-gray-500 dark:text-gray-400 cursor-pointer hover:text-green-600 dark:hover:text-green-400"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {filteredEmployees.map((employee) => (
                <tr
                  key={employee.id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  {/* User */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-3">
                      <span className="font-medium text-gray-900 dark:text-gray-100">
                        {employee.name}
                      </span>
                    </div>
                  </td>

                  {/* Status */}
                  <td
                    className={`px-6 py-4 whitespace-nowrap font-semibold ${
                      employee.status === "Active"
                        ? "text-green-600 dark:text-green-400"
                        : "text-red-600 dark:text-red-400"
                    }`}
                  >
                    {employee.status}
                  </td>

                  {/* Phone */}
                  <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                    {employee.mobile}
                  </td>

                  {/* Email */}
                  <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                    {employee.email}
                  </td>

                  {/* Role */}
                  <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                    {employee.role}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                    {employee.country}
                  </td>


                  {/* Registration Date */}
                  <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                    {format(new Date(employee.registrationDate), "dd/MM/yyyy")}
                  </td>

                  {/* Verified Status */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 py-1 text-xs font-semibold rounded-full ${
                        employee.verified
                          ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                          : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200"
                      }`}
                    >
                      {employee.verified ? "Verified" : "Unverified"}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                    {employee.redemptions}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-gray-100">
                    {employee.wins}
                  </td>
                  {/* Actions */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => setViewedEmployee(employee)}
                      aria-label="View Employee Details"
                      className="text-gray-500 hover:text-green-600 dark:text-gray-400 dark:hover:text-green-400 transition-colors"
                    >
                      <ViewIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Employee Details Modal */}
        {viewedEmployee && (
          <EmployeeDetailsModal
            employee={viewedEmployee}
            handleToggle={viewsEmployeeDetails}
            onClose={() => setViewedEmployee(null)}
          />
        )}
      </div>
    </div>
  );
};

export default UserManagementDashboard;
