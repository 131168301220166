import React, { createContext, useContext, useEffect, useState } from 'react';
import { GET_NOTIFICATION_API } from '../../utils/constant';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const addNotification = (notification) => {
    setNotifications(prev => [notification, ...prev]);
    setUnreadCount(prev => prev + 1);
  };

  const markAllAsRead = () => {
    setUnreadCount(0);
  };

  const deleteNotification = (id) => {
    setNotifications(prev => prev.filter(n => n.id !== id));
    setUnreadCount(prev => Math.max(0, prev - 1));
  };

  const notificationHistory = async () => {
     const response = await fetch(GET_NOTIFICATION_API,{
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
     })
     const data = await response.json()
     setNotifications(data.notifications)
   }
useEffect(() => {
  notificationHistory()
},[])
  return (
    <NotificationContext.Provider 
      value={{ 
        notifications, 
        unreadCount, 
        addNotification, 
        markAllAsRead, 
        deleteNotification 
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};

export default NotificationContext;