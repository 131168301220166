export const LOGIN_API = "https://kiwii.shop:5000/api/v1/admin/login"

export const SIGNUP_API = "https://kiwii.shop:5000/api/v1/admin/signup"

export const ADD_CAMPAIGN_API = "https://kiwii.shop:5000/api/v1/campaigns"

export const LISTBRAND_API = "https://kiwii.shop:5000/api/v1/brands"

export const LISTCAMPAIGN_API = "https://kiwii.shop:5000/api/v1/campaigns"

export const LISTPRIZE_API = "https://kiwii.shop:5000/api/v1/prizes"

export const UPDATE_PRICE_API = "https://kiwii.shop:5000/api/v1/prizes"

export const DELETE_PRIZE_API = "https://kiwii.shop:5000/api/v1/prizes"

export const ADDBRAND_API = "https://kiwii.shop:5000/api/v1/brands/"

export const UPDATEBRAND_API = "https://kiwii.shop:5000/api/v1/brands"

export const DELETEBRAND_API  = "https://kiwii.shop:5000/api/v1/brands"

export const CAMPAIGN_DELETE_API = "https://kiwii.shop:5000/api/v1/campaigns/"

export const CAMPAIGN_UPDATE_API = "https://kiwii.shop:5000/api/v1/campaigns/"

export const ADD_NOTIFICATION_API = "https://kiwii.shop:5000/api/v1/notification/send-to-user"

export const USERS_LIST_API = "https://kiwii.shop:5000/api/v1/admin/list-all-users"

export const GET_NOTIFICATION_API = "https://kiwii.shop:5000/api/v1/notification/all"

export const USER_STATUS_API = "https://kiwii.shop:5000/api/v1/admin/change-user-status/"