import { format } from "date-fns";
import { useState } from "react";
import {
  IoIosSwitch,
  IoMdCall,
  IoIosPeople,
  IoMdHeart,
  IoMdLogIn,
} from "react-icons/io";
import { TfiEmail } from "react-icons/tfi";

export const EmployeeDetailsModal = ({ employee, onClose,handleToggle }) => {
  const [activeStatus, setActiveStatus] = useState(employee.status);

  const handleToggleStatus = () => {

   handleToggle()
   onClose()
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/30  z-50 p-4">
      <div className="bg-gradient-to-br from-white to-gray-50 dark:from-gray-900 dark:to-gray-800 rounded-2xl shadow-2xl max-w-2xl w-full p-8 relative border border-gray-200 dark:border-gray-700">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 bg-gray-100 dark:bg-gray-800 rounded-full p-2 text-gray-500 hover:text-gray-800 dark:hover:text-gray-200 transition-colors"
        >
          ✕
        </button>

        {/* Header with Avatar */}
        <div className="flex items-center mb-8">
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-purple-500 rounded-full blur-md opacity-20"></div>
            <img
              src={employee.avatar}
              alt={employee.name}
              className="w-20 h-20 rounded-full object-cover border-4 border-white dark:border-gray-800 relative z-10"
            />
          </div>
          <div className="ml-6">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
              {employee.name}
            </h2>
            <p className="text-gray-600 dark:text-gray-400 text-lg">{employee.role}</p>
          </div>
        </div>

        {/* Status and Role */}
        <div className="flex justify-between items-center mb-6 space-x-4">
          <div className="bg-gradient-to-r from-blue-50 to-blue-100 dark:from-gray-800 dark:to-gray-700 p-4 rounded-xl flex items-center gap-3 w-1/2 shadow-sm">
            <div className="flex-1">
              <div className="flex gap-2 items-center mb-1">
                <IoIosSwitch c className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                <p className="text-sm text-gray-600 dark:text-gray-300">Status</p>
              </div>
              <span
                className={`font-semibold text-lg ${
                  activeStatus === "active"
                    ? "text-green-600 dark:text-green-400"
                    : "text-red-600 dark:text-red-400"
                }`}
              >
                {activeStatus}
              </span>
            </div>
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={activeStatus === "Active"}
                onChange={handleToggleStatus}
                className="sr-only"
              />
              <div className={`w-14 h-7 rounded-full transition-all duration-300 ${
                activeStatus === "active" ? "bg-green-500" : "bg-red-500"
              }`}>
                <div
                  className={`w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 mt-1 ${
                    activeStatus === "active" ? "translate-x-8" : "translate-x-1"
                  }`}
                ></div>
              </div>
            </label>
          </div>

          <div className="bg-gradient-to-r from-purple-50 to-purple-100 dark:from-gray-800 dark:to-gray-700 p-4 rounded-xl w-1/2 shadow-sm">
            <div className="flex gap-2 items-center mb-1">
              <IoIosPeople className="w-5 h-5 text-purple-600 dark:text-purple-400" />
              <p className="text-sm text-gray-600 dark:text-gray-300">Role</p>
            </div>
            <p className="font-semibold text-lg text-gray-900 dark:text-white">{employee.role}</p>
          </div>
        </div>

        {/* Additional Details Grid */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          {[
            {
              icon: <IoMdCall className="w-5 h-5" />,
              label: "Phone",
              value: employee.mobile,
              color: "text-green-600 dark:text-green-400",
              gradient: "from-green-50 to-green-100"
            },
            {
              icon: <TfiEmail className="w-5 h-5" />,
              label: "Email",
              value: employee.email,
              color: "text-yellow-600 dark:text-yellow-400",
              gradient: "from-yellow-50 to-yellow-100"
            },
            {
              icon: <IoMdHeart className="w-5 h-5" />,
              label: "Win Count",
              value: "30",
              color: "text-pink-600 dark:text-pink-400",
              gradient: "from-pink-50 to-pink-100"
            },
            {
              icon: <IoMdHeart className="w-5 h-5" />,
              label: "Redemption Count",
              value: "70",
              color: "text-indigo-600 dark:text-indigo-400",
              gradient: "from-indigo-50 to-indigo-100"
            }
          ].map((item, index) => (
            <div key={index} className={`bg-gradient-to-r ${item.gradient} dark:from-gray-800 dark:to-gray-700 p-4 rounded-xl shadow-sm`}>
              <div className="flex gap-2 items-center mb-1">
                <div className={item.color}>{item.icon}</div>
                <p className="text-sm text-gray-600 dark:text-gray-300">{item.label}</p>
              </div>
              <p className="font-semibold text-lg text-gray-900 dark:text-white">{item.value}</p>
            </div>
          ))}
        </div>

        <div className="bg-gradient-to-r from-orange-50 to-orange-100 dark:from-gray-800 dark:to-gray-700 p-4 rounded-xl shadow-sm">
          <div className="flex gap-2 items-center mb-1">
            <IoMdLogIn className="w-5 h-5 text-orange-600 dark:text-orange-400" />
            <p className="text-sm text-gray-600 dark:text-gray-300">Registration Date</p>
          </div>
          <p className="font-semibold text-lg text-gray-900 dark:text-white">
            {format(new Date(employee.registrationDate), "dd/MM/yyyy")}
          </p>
        </div>

        {/* Footer */}
        <div className="mt-8 flex justify-end">
          <button
            onClick={onClose}
            className="px-8 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-xl hover:from-green-600 hover:to-green-700 transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailsModal;