import React from "react";
import { useTheme } from "./ThemeContext";
import { Bell } from "lucide-react";
import ProfileEditor from "../pages/ProfileEditor";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "./context/NotificationContext";

const Header = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { unreadCount, markAllAsRead } = useNotifications();

  const handleBellClick = () => {
    navigate("/notificationpage");
    markAllAsRead();
  };

  return (
    <div className={`${theme === "dark" ? "bg-gray-900 text-white" : "bg-white text-gray-800"}`}>
      <header 
        className={`flex items-center justify-end px-8 shadow-lg p-2 gap-4 border-b 
        ${theme === "dark" 
          ? "bg-gray-800 border-gray-700 shadow-inner shadow-green-300" 
          : "bg-blue-100 border-gray-100 shadow-inner shadow-blue-300"}`}
      >
        <div className="relative">
          <button 
            onClick={handleBellClick}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
          >
            <Bell className="w-6 h-6" />
            {unreadCount > 0 && (
              <span className="absolute -top-1 -right-1 bg-green-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                {unreadCount > 9 ? '9+' : unreadCount}
              </span>
            )}
          </button>
        </div>
        <ProfileEditor />
      </header>
    </div>
  );
};

export default Header;